import './App.css';

function App() {
  return (
    <div>
      {/* Navigation */}
      <header>
        <nav>
          <ul>
            <li><a href="#home">Home</a></li>
            <li><a href="#about">About</a></li>
            <li><a href="#projects">Projects</a></li>
            <li><a href="#contact">Contact</a></li>
          </ul>
        </nav>
      </header>

      {/* Hero Section */}
      <section id="home" className="hero">
        <div className="hero-content">
          <h1>Hi! I'm George Fang.</h1>
          <p>Creating immersive experiences through sleek design and advanced technology.</p>
        </div>
      </section>

      {/* About Section */}
      <section id="about">
        <h2>About Me</h2>
        <p>Coming soon...</p>
      </section>

      {/* Projects Section */}
      <section id="projects">
        <h2>Projects</h2>
        <div className="project-grid">
          {/* Add projects here */}
        </div>
      </section>

      {/* Contact Section */}
      <section id="contact">
        <h2>Contact</h2>
        <p>Let's get in touch!</p>
        <ul>
          <li><a href="mailto:george.fang@duke.edu">Email</a></li>
          <li><a href="https://www.linkedin.com/in/georgefang1">LinkedIn</a></li>
        </ul>
      </section>

      <footer>
        <p>© 2024 George Fang. All Rights Reserved.</p>
      </footer>
    </div>
  );
}

export default App;
